
.scrollbar-box{
  height: 100%;
}
.detail-scrollbar{
  height: calc(100% - 100px);
  ::v-deep .el-scrollbar__wrap{
    overflow-x: hidden;
    .el-scrollbar__view{
      padding: 20px;
    }
  }
}
.detail-box{
  display: flex;
  flex-direction: column;
  border: 1px solid #EEEEEE;
  .detail-header{
    display: flex;
    height: 60px;
    background: #F6F6F6;
    padding: 0 50px;
    box-sizing: border-box;
    border: 1px solid #EEEEEE;
    border-bottom: none;
    .header-item{
      display: flex;
      align-items: center;
      flex: 1;
      width: 1%;
      color: #666666;
      font-size: 16px;
    }
  }
  .detail-body{
    display: flex;
    flex-direction: column;
    .body-item{
      height: 70px;
      display: flex;
      padding: 0 50px;
      box-sizing: border-box;
      //align-items: center;
      border: 1px solid #E5E5E5;
      border-bottom: none;
      .item-left,.item-right{
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #333;
        flex: 1;
        width: 1%;
        .el-input{
          margin-left: 8px;
          width: 290px;
        }
        &.item-left{
          span{
            min-width: 70px;
            text-align: right;
          }
        }
      }
    }
  }
}
